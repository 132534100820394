<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1>{{ $t('message.sla') }}</h1>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_1') }}</h4>
            <span v-html="$t('pages.sla.content_1')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_2') }}</h4>
            <span v-html="$t('pages.sla.content_2')"></span>
          </div>
          <div class="mb-5">
            <h4 class="mb-5">{{ $t('pages.sla.title_3') }}</h4>
            <span v-html="$t('pages.sla.content_3')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_4') }}</h4>
            <span v-html="$t('pages.sla.content_4')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_5') }}</h4>
            <span v-html="$t('pages.sla.content_5')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_6') }}</h4>
            <span v-html="$t('pages.sla.content_6')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_7') }}</h4>
            <span v-html="$t('pages.sla.content_7')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.sla.title_8') }}</h4>
            <span v-html="$t('pages.sla.content_8')"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tos"
}
</script>

<style scoped>

</style>
